<template>
  <div>
    <b-icon @click="showModal = true" icon="plus-square" style="cursor: pointer;"></b-icon> <span @click="showModal = true" style="cursor: pointer;">Add new stock</span>
    <b-modal
      v-model="showModal"
      size="xl"
      @hidden="resetForms(true)"
      hide-footer
    >
      <b-row class="mb-3">
        <b-col class="text-center">
          <template v-if="!vehicleRegLocked">
            <b-input-group>
              <b-form-input
                id="vehicle-reg"
                class="carReg"
                v-model="vehicleReg"
                trim
                :disabled="regSearchSubmitted"
                @keydown.space.prevent
                @keydown.enter="vehicleRegFind"
                placeholder="Registration">
              </b-form-input>
              <b-button
                id="searchButton"
                variant="outline-primary"
                size="sm"
                :disabled="regSearchSubmitted"
                @click="vehicleRegFind"><b-icon icon="search"></b-icon></b-button>
            </b-input-group>
            <b-badge v-if="regLookupRegInUse" variant="danger">Vehicle registration already listed</b-badge>
          </template>
          <template v-else>
            <h2>
              <b-badge variant="primary" class="carReg">{{ vehicleReg }}</b-badge>
            </h2>
            <b-badge v-if="regLookupInvalidReg" variant="danger">Vehicle registration not recognised</b-badge>
          </template>
        </b-col>
        <b-col class="text-right">
          <b-button
            v-if="vehicleRegLocked"
            @click="vehicleRegNewSearch"
            pill
            variant="outline-primary"
            size="sm"
            style="margin-top: 5px;"><b-icon icon="search"></b-icon> New Search</b-button>
        </b-col>
      </b-row>

      <div class="accordion" role="tablist">
        <b-card no-body class="mb-1">
          <b-card-header header-tag="header" class="p-1" role="tab">
            <b-button
              block
              v-b-toggle.accordion-1
              variant="outline-primary">Vehicle</b-button>
          </b-card-header>
          <b-collapse id="accordion-1" visible accordion="my-accordion" v-model="accordianVehicle" role="tabpanel">
            <b-card-body>
              <b-card-text>
                <b-row>
                  <b-col>

                    <b-row>
                      <b-col class="text-right">
                        <label for="vehicle-make">Make</label>
                      </b-col>
                      <b-col>
                        <b-form-input
                          id="vehicle-make"
                          v-model="vehicleMake"
                          size="sm"></b-form-input>
                      </b-col>
                    </b-row>

                    <b-row>
                      <b-col class="text-right">
                        <label for="vehicle-model">Model</label>
                      </b-col>
                      <b-col>
                        <b-form-input
                          id="vehicle-model"
                          v-model="vehicleModel"
                          size="sm"></b-form-input>
                      </b-col>
                    </b-row>

                    <b-row>
                      <b-col class="text-right">
                        <label for="vehicle-variant">Variant</label>
                      </b-col>
                      <b-col>
                        <b-form-input
                          id="vehicle-variant"
                          v-model="vehicleVariant"
                          size="sm"></b-form-input>
                      </b-col>
                    </b-row>

                    <b-row>
                      <b-col class="text-right">
                        <label for="vehicle-body-type">Body Type</label>
                      </b-col>
                      <b-col>
                        <b-form-input
                          id="vehicle-body-type"
                          v-model="vehicleBodyType"
                          size="sm"></b-form-input>
                      </b-col>
                    </b-row>

                    <b-row>
                      <b-col class="text-right">
                        <label for="vehicle-colour">Colour</label>
                      </b-col>
                      <b-col>
                        <b-form-input
                          id="vehicle-colour"
                          v-model="vehicleColour"
                          size="sm"></b-form-input>
                      </b-col>
                    </b-row>

                    <b-row>
                      <b-col class="text-right">
                        <label for="vehicle-seats">Seats</label>
                      </b-col>
                      <b-col>
                        <b-form-input
                          id="vehicle-seats"
                          type="number"
                          v-model.number="vehicleSeats"
                          size="sm"></b-form-input>
                      </b-col>
                    </b-row>

                    <b-row>
                      <b-col class="text-right">
                        <label for="vehicle-engine-size">Engine Size</label>
                      </b-col>
                      <b-col>
                        <b-form-input
                          id="vehicle-engine-size"
                          type="number"
                          v-model.number="vehicleEngineSize"
                          size="sm"></b-form-input>
                      </b-col>
                    </b-row>

                    <b-row>
                      <b-col class="text-right">
                        <label for="vehicle-fuel-type">Fuel Type</label>
                      </b-col>
                      <b-col>
                        <b-form-input
                          id="vehicle-fuel-type"
                          v-model="vehicleFuelType"
                          size="sm"></b-form-input>
                      </b-col>
                    </b-row>

                    <b-row>
                      <b-col class="text-right">
                        <label for="vehicle-power">Power (BHP)</label>
                      </b-col>
                      <b-col>
                        <b-form-input
                          id="vehicle-power"
                          type="number"
                          v-model.number="vehiclePower"
                          size="sm"></b-form-input>
                      </b-col>
                    </b-row>

                    <b-row>
                      <b-col class="text-right">
                        <label for="vehicle-transmission">Transmission</label>
                      </b-col>
                      <b-col>
                        <b-form-input
                          id="vehicle-transmission"
                          v-model="vehicleTransmission"
                          size="sm"></b-form-input>
                      </b-col>
                    </b-row>

                    <b-row>
                      <b-col class="text-right">
                        <label for="vehicle-registration-date">First Registration</label>
                      </b-col>
                      <b-col>
                        <b-form-input
                          id="vehicle-registration-date"
                          v-model="vehicleRegistrationDate"
                          placeholder="DD/MM/YYYY"
                          size="sm"></b-form-input>
                      </b-col>
                    </b-row>

                    <b-row>
                      <b-col class="text-right">
                        <label for="vehicle-tax-band">Tax Band</label>
                      </b-col>
                      <b-col>
                        <b-form-input
                          id="vehicle-tax-band"
                          v-model="vehicleTaxBand"
                          size="sm"></b-form-input>
                      </b-col>
                    </b-row>

                    <b-row>
                      <b-col class="text-right">
                        <label for="vehicle-mileage">Mileage</label>
                      </b-col>
                      <b-col>
                        <b-form-input
                          id="vehicle-mileage"
                          type="number"
                          v-model.number="vehicleMileage"
                          size="sm"></b-form-input>
                      </b-col>
                    </b-row>

                  </b-col>

                  <b-col class="text-center">
                    <img v-if="vehicleStockImage && !vehicleStockImage.unavailable" style="width: 400px;" :src="vehicleStockImage" />
                  </b-col>
                </b-row>

              </b-card-text>
            </b-card-body>
          </b-collapse>
        </b-card>

        <b-card no-body class="mb-1">
          <b-card-header header-tag="header" class="p-1" role="tab">
            <b-button
              block
              v-b-toggle.accordion-2
              variant="outline-primary"
              :disabled="!vehicleStepCheck">Price</b-button>
          </b-card-header>
          <b-collapse id="accordion-2" accordion="my-accordion" role="tabpanel">
            <b-card-body>
              <b-card-text>

                <b-row>
                  <b-col>
                    <b-row>
                      <b-col class="text-right mt-1">
                        <label for="vehicle-price">Asking Price</label>
                      </b-col>
                      <b-col>
                        <b-input-group>
                          <b-input-group-prepend>
                            <span class="input-group-text">£</span>
                          </b-input-group-prepend>
                          <b-form-input
                            id="vehicle-price"
                            type="number"
                            v-model.number="vehiclePrice"></b-form-input>
                        </b-input-group>
                      </b-col>
                    </b-row>
                  </b-col>
                  <!-- <b-col>
                    <template v-if="vehicleValuation && !vehicleValuation.unavailable">
                      <b-row>
                        <b-col class="text-right">
                          <label for="vehicle-price">OTR</label>
                        </b-col>
                        <b-col>
                          <b-button @click="vehiclePrice=vehicleValuation.OTR" pill variant="outline-primary" size="sm">£{{ vehicleValuation.OTR }}</b-button>
                        </b-col>
                      </b-row>
                      <b-row>
                        <b-col class="text-right">
                          <label for="vehicle-price">Forecourt</label>
                        </b-col>
                        <b-col>
                          <b-button @click="vehiclePrice=vehicleValuation.DealerForecourt" pill variant="outline-primary" size="sm">£{{ vehicleValuation.DealerForecourt }}</b-button>
                        </b-col>
                      </b-row>
                      <b-row>
                        <b-col class="text-right">
                          <label for="vehicle-price">P/X</label>
                        </b-col>
                        <b-col>
                          <b-button @click="vehiclePrice=vehicleValuation.PartExchange" pill variant="outline-primary" size="sm">£{{ vehicleValuation.PartExchange }}</b-button>
                        </b-col>
                      </b-row>
                      <b-row>
                        <b-col class="text-right">
                          <label for="vehicle-price">Auction</label>
                        </b-col>
                        <b-col>
                          <b-button @click="vehiclePrice=vehicleValuation.Auction" pill variant="outline-primary" size="sm">£{{ vehicleValuation.Auction }}</b-button>
                        </b-col>
                      </b-row>
                      <b-row>
                        <b-col class="text-right">
                          <label for="vehicle-price">Private (Clean)</label>
                        </b-col>
                        <b-col>
                          <b-button @click="vehiclePrice=vehicleValuation.PrivateClean" pill variant="outline-primary" size="sm">£{{ vehicleValuation.PrivateClean }}</b-button>
                        </b-col>
                      </b-row>
                      <b-row>
                        <b-col class="text-right">
                          <label for="vehicle-price">Private (Average)</label>
                        </b-col>
                        <b-col>
                          <b-button @click="vehiclePrice=vehicleValuation.PrivateAverage" pill variant="outline-primary" size="sm">£{{ vehicleValuation.PrivateAverage }}</b-button>
                        </b-col>
                      </b-row>
                      <b-row>
                        <b-col class="text-right">
                          <label for="vehicle-price">Trade (Retail)</label>
                        </b-col>
                        <b-col>
                          <b-button @click="vehiclePrice=vehicleValuation.TradeRetail" pill variant="outline-primary" size="sm">£{{ vehicleValuation.TradeRetail }}</b-button>
                        </b-col>
                      </b-row>
                      <b-row>
                        <b-col class="text-right">
                          <label for="vehicle-price">Trade (Average)</label>
                        </b-col>
                        <b-col>
                          <b-button @click="vehiclePrice=vehicleValuation.TradeAverage" pill variant="outline-primary" size="sm">£{{ vehicleValuation.TradeAverage }}</b-button>
                        </b-col>
                      </b-row>
                      <b-row>
                        <b-col class="text-right">
                          <label for="vehicle-price">Trade (Poor)</label>
                        </b-col>
                        <b-col>
                          <b-button @click="vehiclePrice=vehicleValuation.TradePoor" pill variant="outline-primary" size="sm">£{{ vehicleValuation.TradePoor }}</b-button>
                        </b-col>
                      </b-row>
                    </template>
                    <b-badge v-else variant="warning">Valuations unavailable</b-badge>
                  </b-col> -->
                </b-row>

              </b-card-text>
            </b-card-body>
          </b-collapse>
        </b-card>

        <b-card no-body class="mb-1">
          <b-card-header header-tag="header" class="p-1" role="tab">
            <b-button
              block
              v-b-toggle.accordion-3
              variant="outline-primary"
              @click="vehicleSpecOptionsLookup"
              :disabled="!vehicleStepCheck || !priceStepCheck">Advert</b-button>
          </b-card-header>
          <b-collapse id="accordion-3" accordion="my-accordion" role="tabpanel">
            <b-card-body>
              <b-card-text>

                <b-row>
                  <b-col class="text-right" cols="3">
                    <label for="advert-title">Title</label>
                  </b-col>
                  <b-col>
                    <b-form-input
                      id="advert-title"
                      v-model="advertTitle"
                      size="sm"></b-form-input>
                  </b-col>
                  <b-col>
                  </b-col>
                </b-row>

                <b-row>
                  <b-col class="text-right" cols="3">
                    <label for="advert-subtitle">Subtitle</label>
                  </b-col>
                  <b-col>
                    <b-form-input
                      id="advert-subtitle"
                      v-model="advertSubtitle"
                      size="sm"></b-form-input>
                  </b-col>
                </b-row>

                <b-row>
                  <b-col class="text-right" cols="3">
                    <label for="advert-tag">Tag</label>
                  </b-col>
                  <b-col>
                    <b-form-input
                      id="advert-tag"
                      v-model="advertTag"
                      maxlength="15"
                      size="sm"></b-form-input>
                  </b-col>
                </b-row>

                <b-row>
                  <b-col class="text-right" cols="3">
                    <label for="advert-description">Description</label>
                  </b-col>
                  <b-col>
                    <b-form-textarea
                      id="advert-description"
                      v-model="advertDescription"
                      size="sm"
                      rows="3"
                      max-rows="6"
                    ></b-form-textarea>
                  </b-col>
                </b-row>

                <b-row class="mt-3 mb-3">
                  <b-col class="text-right" cols="3">
                    <label for="advert-features">Features</label>
                  </b-col>
                  <b-col>
                    <b-badge v-if="!vehicleEquipmentList" variant="warning">Features unavailable</b-badge>
                    <template v-else>
                      <b-badge
                        v-for="(feature, index) in vehicleEquipmentSelectedSorted"
                        :key="index"
                        style="margin-right: 5px;"
                        pill
                        variant="primary">{{ feature.Name }}</b-badge>
                      <b-badge @click="showFeaturesModal=true" variant="warning" pill style="cursor: pointer;">Add / Remove</b-badge>
                    </template>
                  </b-col>
                </b-row>

                <b-row>
                  <b-col class="text-right" cols="3">
                    <label for="advert-owners">Owners</label>
                  </b-col>
                  <b-col cols="2">
                    <b-form-input
                      id="advert-owners"
                      type="number"
                      v-model.number="vehicleOwners"
                      size="sm"></b-form-input>
                  </b-col>
                  <b-col>
                  </b-col>
                </b-row>

                <b-row>
                  <b-col class="text-right" cols="3">
                    <label for="advert-service-history">Service History</label>
                  </b-col>
                  <b-col cols="2">
                    <b-form-select
                      id="advert-service-history"
                      v-model="vehicleServiceHistory"
                      :options="serviceHistoryOptions"
                      size="sm"></b-form-select>
                  </b-col>
                  <b-col>
                  </b-col>
                </b-row>

                <b-row>
                  <b-col class="text-right" cols="3">
                    <label for="advert-mot-due">MOT Due</label>
                  </b-col>
                  <b-col cols="2">
                    <b-form-input
                      id="advert-mot-due"
                      v-model="vehicleMotDue"
                      placeholder="DD/MM/YYYY"
                      size="sm"></b-form-input>
                  </b-col>
                  <b-col>
                  </b-col>
                </b-row>

              </b-card-text>
            </b-card-body>
          </b-collapse>
        </b-card>

        <b-card no-body class="mb-1">
          <b-card-header header-tag="header" class="p-1" role="tab">
            <b-button
              block
              v-b-toggle.accordion-4
              variant="outline-primary"
              :disabled="!vehicleStepCheck || !priceStepCheck || !advertStepCheck">Photos</b-button>
          </b-card-header>
          <b-collapse id="accordion-4" accordion="my-accordion" role="tabpanel">
            <b-card-body>
              <b-card-text>
                <b-row>
                  <b-col>
                    <b-button
                      @click="uploadImageBtnClick"
                      :disabled="cloudUploadProgress !== 0 && cloudUploadProgress !== 100"
                      pill
                      variant="outline-primary"
                      size="sm">
                      <template v-if="cloudUploadProgress !== 0 && cloudUploadProgress !== 100">
                        <b-icon icon="upload"></b-icon> Progress: {{ cloudUploadProgress}}%
                      </template>
                      <template v-else>
                        <b-icon icon="upload"></b-icon> Add Photo
                      </template>
                    </b-button>
                    <input
                      type="file"
                      ref="fileUpload"
                      style="display: none;"
                      @change="uploadImage"
                      accept="image/*"
                      multiple />
                  </b-col>
                  <b-col v-if="uploadedPhotos.length" class="text-right">
                    <b-badge variant="info"> Click &amp; drag to reorder images</b-badge>
                  </b-col>
                </b-row>
                <b-row class="mt-3">
                  <b-col class="text-center">
                    <draggable v-model="uploadedPhotos">
                      <div v-for="(photo, index) in uploadedPhotos" class="photo-preview" style="display: inline-block; margin: 5px;" :key="index">
                        <img
                          @click="deletePhoto($event, photo)"
                          :src="photo.url"
                          :alt="photo.storageRef"
                          class="photo-preview" />
                        <b-button class="btnDelete" variant="primary" @click="deletePhoto(photo)" size="sm">Delete</b-button>
                      </div>
                    </draggable>
                  </b-col>
                </b-row>
              </b-card-text>
            </b-card-body>
          </b-collapse>
        </b-card>

        <b-card no-body class="mb-1">
          <b-card-header header-tag="header" class="p-1" role="tab">
            <b-button
              block
              v-b-toggle.accordion-5
              variant="outline-primary"
              :disabled="!vehicleStepCheck || !priceStepCheck || !advertStepCheck || !photosStepCheck">Save / Publish</b-button>
          </b-card-header>
          <b-collapse id="accordion-5" accordion="my-accordion" role="tabpanel">
            <b-card-body>
              <b-card-text>
                <b-row>
                  <b-col class="text-right">
                    <b-form-checkbox v-model="vehicleFeatured" :disabled="!vehicleActive">
                      Featured
                    </b-form-checkbox>
                  </b-col>
                  <b-col class="text-left">
                    <b-form-checkbox v-model="vehicleActive" :disabled="vehicleFeatured">
                      Publish
                    </b-form-checkbox>
                  </b-col>
                </b-row>
                <b-row>
                  <b-col class="text-center">
                    <b-button @click="createAdvert" variant="primary">Create Advert</b-button>
                  </b-col>
                </b-row>
              </b-card-text>
            </b-card-body>
          </b-collapse>
        </b-card>
      </div>
    </b-modal>

    <!-- Features modal -->
    <b-modal
      v-model="showFeaturesModal"
      size="lg"
      hide-footer
    >
      <template v-if="vehicleEquipmentList">
        <b-row v-for="(category, index) in vehicleEquipmentCategories" :key="index">
          <b-col cols="3" class="text-right">
            <label>{{ category }}</label>
          </b-col>
          <b-col>
            <b-badge
              v-for="(equipment, index) in getCategoryEquipment(category)"
              :key="index"
              style="cursor: pointer; margin-right: 5px;"
              pill
              @click="addOrRemoveEquipment(equipment)"
              :variant="checkEquipmentAdded(equipment)">{{ equipment.Name }}</b-badge>
          </b-col>
        </b-row>
      </template>
    </b-modal>

  </div>
</template>

<script>
import draggable from 'vuedraggable'
import moment from 'moment'
import { mapState, mapGetters, mapActions } from 'vuex'

export default {
  name: 'AddStock',
  components: {
    draggable
  },
  data() {
    return {
      showModal: false,
      showFeaturesModal: false,
      accordianVehicle: true,

      timestampFormat: 'YYYY-MM-DDTHH:mm:ss',

      regSearchSubmitted: false,
      regLookupInvalidReg: false,
      regLookupRegInUse: false,

      vehicleReg: '',
      vehicleRegLocked: false,

      vehicleMake: '',
      vehicleModel: '',
      vehicleVariant: '',
      vehicleBodyType: '',
      vehicleFuelType: '',
      vehicleColour: '',
      vehicleTransmission: '',
      vehicleEngineSize: '',
      vehiclePower: '',
      vehicleSeats: '',
      vehicleRegistrationDate: '',
      vehicleTaxBand: '',
      vehicleMileage: '',
      vehicleStockImage: '',

      vehicleValuation: null,
      vehiclePrice: '',

      advertTitle: '',
      advertSubtitle: '',
      advertTag: '',
      advertDescription: '',

      vehicleEquipmentList: null,
      vehicleEquipmentSelected: [],
      
      vehicleOwners: '',
      vehicleServiceHistory: '',
      vehicleMotDue: '',
      serviceHistoryOptions: [
        'Full service history',
        'Part service history',
        'No service history',
        'First service not due'
      ],

      uploadedPhotos: [],

      vehicleActive: false,
      vehicleFeatured: false
      
    }
  },
  computed: {
    ...mapState('stock', ['cloudUploadProgress']),
    ...mapGetters('stock', ['getAllVehicles', 'getFeaturedVehicles']),
    ...mapState('user', ['user']),

    vehicleStepCheck() {
      return this.vehicleReg &&
        this.vehicleMake &&
        this.vehicleModel &&
        this.vehicleVariant &&
        this.vehicleBodyType &&
        this.vehicleFuelType &&
        this.vehicleColour &&
        this.vehicleTransmission &&
        this.vehicleEngineSize &&
        this.vehiclePower &&
        this.vehicleSeats &&
        this.vehicleRegistrationDate &&
        this.vehicleTaxBand &&
        this.vehicleMileage
      ? true : false
    },
    priceStepCheck() {
      return this.vehiclePrice ? true : false
    },
    advertStepCheck() {
      return this.advertTitle &&
        this.advertSubtitle &&
        this.advertDescription &&
        this.vehicleServiceHistory &&
        this.vehicleMotDue
      ? true : false
    },
    photosStepCheck() {
      return true
    },
    publishStepCheck() {
      return this.priceStepCheck && this.vehicleStepCheck && this.advertStepCheck && this.photosStepCheck
    },

    vehicleEquipmentCategories() {
      return this.vehicleEquipmentList && !this.vehicleEquipmentList.unavailable ? Array.from(new Set(this.vehicleEquipmentList.map((item) => item.Category))).sort() : null
    },
    vehicleEquipmentSelectedSorted() {
      return this.vehicleEquipmentSelected.sort((a, b) => { return a.Name > b.Name ? 1 : -1 })
    }
  },
  watch: {
    vehicleReg() {
      if (this.vehicleReg) {
        this.vehicleReg = this.vehicleReg.toUpperCase()
        this.vehicleReg = this.vehicleReg.replace(' ', '')
      }
    },
    vehicleMake() {
      if (this.vehicleMake) this.vehicleMake = this.vehicleMake.toUpperCase()
    },
    vehicleModel() {
      if (this.vehicleModel) this.vehicleModel = this.vehicleModel.toUpperCase()
    },
    vehicleVariant() {
      if (this.vehicleVariant) this.vehicleVariant = this.vehicleVariant.toUpperCase()
    },
    vehicleBodyType() {
      if (this.vehicleBodyType) this.vehicleBodyType = this.vehicleBodyType.toUpperCase()
    },
    vehicleFuelType() {
      if (this.vehicleFuelType) this.vehicleFuelType = this.vehicleFuelType.toUpperCase()
    },
    vehicleColour() {
      if (this.vehicleColour) this.vehicleColour = this.vehicleColour.toUpperCase()
    },
    vehicleTransmission() {
      if (this.vehicleTransmission) this.vehicleTransmission = this.vehicleTransmission.toUpperCase()
    },
    vehicleTaxBand() {
      if (this.vehicleTaxBand) this.vehicleTaxBand = this.vehicleTaxBand.toUpperCase()
    },
  },
  beforeDestroy() {
    this.resetForms(true)
  },
  methods: {
    ...mapActions('stock', [
      'getVehicleDetailsFromReg',
      'getVehicleMotTaxFromReg',
      'getVehicleValuationFromReg',
      'getVehicleStockImageFromReg',
      'getVehicleSpecOptionsFromReg',
      'cloudStorageUpload',
      'cloudStorageDelete',
      'dbAddVehicle',
      'logAddEvent',
    ]),

    resetForms(clearReg) {
      if (clearReg) {
        this.vehicleReg = ''
        this.vehicleRegLocked = false
      }
      this.regLookupInvalidReg = false
      this.regLookupRegInUse = false

      this.vehicleMake = ''
      this.vehicleModel = ''
      this.vehicleVariant = ''
      this.vehicleBodyType = ''
      this.vehicleFuelType = ''
      this.vehicleColour = ''
      this.vehicleTransmission = ''
      this.vehicleEngineSize = ''
      this.vehiclePower = ''
      this.vehicleSeats = ''
      this.vehicleRegistrationDate = ''
      this.vehicleTaxBand = ''
      this.vehicleMileage = ''
      this.vehicleStockImage = ''

      this.vehicleValuation = null
      this.vehiclePrice = ''

      this.advertTitle = ''
      this.advertSubtitle = ''
      this.advertTag = ''
      this.advertDescription = ''
      this.vehicleEquipmentList = null
      this.vehicleEquipmentSelected = []
      this.vehicleOwners = ''
      this.vehicleServiceHistory = ''
      this.vehicleMotDue = ''

      this.vehicleActive = false
      this.vehicleFeatured = false

      this.deleteUploadedPhotos()
      
    },

    vehicleRegFind() {
      this.regSearchSubmitted = true
      this.regLookupRegInUse = false
      if (this.getAllVehicles.some(v => v.vehicle && v.vehicle.reg === this.vehicleReg)) {
        this.regLookupRegInUse = true
      } else {
        this.vehicleRegLocked = true
        this.vehicleDetailsLookup()
      }
      this.regSearchSubmitted = false
    },
    vehicleRegNewSearch() {
      this.accordianVehicle = true // Go back to Vehicle step
      this.resetForms()
      this.vehicleRegLocked = false
      this.vehicleReg = ''
    },

    vehicleDetailsLookup() {
      // add addStock logging at this point
      this.getVehicleDetailsFromReg(this.vehicleReg)
        .then(response => {
          
          // log the event of
          this.logAddEvent(this.vehicleReg)

          // console.log(response.data)
          if (response.data.Response.StatusCode === 'Success') {
            const vehicleDetails = response.data.Response.DataItems
            this.vehicleMake = vehicleDetails.VehicleRegistration.Make
            this.vehicleModel = vehicleDetails.SmmtDetails.Range
            this.vehicleVariant = vehicleDetails.SmmtDetails.ModelVariant
            this.vehicleBodyType = vehicleDetails.SmmtDetails.BodyStyle
            this.vehicleFuelType = vehicleDetails.VehicleRegistration.FuelType
            this.vehicleColour = vehicleDetails.VehicleRegistration.Colour
            this.vehicleTransmission = vehicleDetails.SmmtDetails.Transmission
            this.vehicleEngineSize = vehicleDetails.SmmtDetails.EngineCapacity
            this.vehiclePower = Math.round(vehicleDetails.TechnicalDetails.Performance.Power.Bhp)
            this.vehicleSeats = vehicleDetails.TechnicalDetails.Dimensions.NumberOfSeats
            this.vehicleTaxBand = vehicleDetails.VehicleStatus.MotVed.VedBand

            this.advertTitle = vehicleDetails.VehicleRegistration.Make + ' ' + vehicleDetails.SmmtDetails.Range + ' ' + vehicleDetails.SmmtDetails.ModelVariant
            this.vehicleOwners = vehicleDetails.VehicleHistory.NumberOfPreviousKeepers
          
          } else if (response.data.Response.StatusCode === 'KeyInvalid') {
            this.regLookupInvalidReg = true
          }

          // Get MOT/Tax data
          this.getVehicleMotTaxFromReg(this.vehicleReg)
            .then(response => {
              // console.log(response.data)
              if (response.data.Response.StatusCode === 'Success') {
                const vehicleDetails = response.data.Response.DataItems
                this.vehicleRegistrationDate = vehicleDetails.VehicleDetails.DateFirstRegistered
                this.vehicleMotDue = vehicleDetails.VehicleStatus.NextMotDueDate
              } else if (response.data.Response.StatusCode === 'KeyInvalid') {
                this.regLookupInvalidReg = true
              }
            })
            .catch(err => {
              console.error(err)
            })

          // Get stock image
          this.getVehicleStockImageFromReg(this.vehicleReg)
            .then(response => {
              // console.log(response.data)
              if (response.data.Response.StatusCode === 'Success') {
                this.vehicleStockImage = response.data.Response.DataItems.VehicleImages.ImageDetailsList[0].ImageUrl
              } else if (response.data.Response.StatusCode === 'KeyInvalid') {
                this.regLookupInvalidReg = true
                this.vehicleStockImage = { unavailable: true }
              } else {
                this.vehicleStockImage = { unavailable: true }
              }
            })
            .catch(err => {
              console.error(err)
              this.vehicleStockImage = { unavailable: true }
            })

        })
        .catch(err => {
          console.error(err)
        })
    },

    // vehicleValuationLookup() {
    //   if (!this.vehicleValuation) {
    //     this.getVehicleValuationFromReg(this.vehicleReg)
    //       .then(response => {
    //         // console.log(response)
    //         if (response.data.Response.StatusCode === 'Success') {
    //           this.vehicleValuation = response.data.Response.DataItems.ValuationList
    //         } else {
    //           this.vehicleValuation = { unavailable: true }
    //         }
    //       })
    //       .catch(err => {
    //         console.error(err)
    //         this.vehicleValuation = { unavailable: true }
    //       })
    //   }
    // },

    vehicleSpecOptionsLookup() {
      if (!this.vehicleEquipmentList) {
        this.getVehicleSpecOptionsFromReg(this.vehicleReg)
          .then(response => {
            // console.log(response.data)
            if (response.data.Response.StatusCode === 'Success') {
              this.vehicleEquipmentList = response.data.Response.DataItems.FactoryEquipmentList
            } else {
              this.vehicleEquipmentList = { unavailable: true }
            }
          })
          .catch(err => {
            console.error(err)
            this.vehicleEquipmentList = { unavailable: true }
          })
      }
    },

    getCategoryEquipment(category) {
      return this.vehicleEquipmentList.filter(equipment => equipment.Category === category)
    },
    addOrRemoveEquipment(equipment) {
      const eIndex = this.vehicleEquipmentSelected.findIndex(ves =>
        ves.Category === equipment.Category &&
        ves.Description === equipment.Description &&
        ves.Name === equipment.Name
      )
      if (eIndex > -1) { // Remove
        this.vehicleEquipmentSelected.splice(eIndex, 1)
      } else { // Add
        this.vehicleEquipmentSelected.push(equipment)
      }
    },
    checkEquipmentAdded(equipment) {
      const eIndex = this.vehicleEquipmentSelected.findIndex(ves =>
        ves.Category === equipment.Category &&
        ves.Description === equipment.Description &&
        ves.Name === equipment.Name
      )
      if (eIndex > -1) return 'primary'
      return 'light'
    },

    uploadImageBtnClick() {
      this.$refs.fileUpload.click()
    },
    uploadImage(event) {
      // const imageData = event.target.files[0]
      const imagesUploaded = event.target.files

      imagesUploaded.forEach(image =>{
        const firebaseStorageRef = this.vehicleReg + '-' + moment().format('YYYYMMDD') + '/' + image.name
          this.cloudStorageUpload({ image, firebaseStorageRef })
            .then(url => {
              this.uploadedPhotos.push({
                storageRef: firebaseStorageRef,
                url: url
              })
            })
            .catch(err => {
              console.error(err)
            })
      })
      
    },
    deletePhoto(photo) {
      const pIndex = this.uploadedPhotos.findIndex(p => p.storageRef === photo.storageRef && p.url === photo.url)
        if (pIndex > -1) {
          this.cloudStorageDelete(photo.storageRef)
            .then(() => {
              this.uploadedPhotos.splice(pIndex, 1)
            })
            .catch(err => {
              console.error(err)
            })
        }
    },
    deleteUploadedPhotos() {
      this.uploadProgress = 0
      this.uploadedPhotos.forEach(photo => {
        this.cloudStorageDelete(photo.storageRef)
          .then(() => {})
          .catch(err => {
            console.error(err)
          })
      })
      this.uploadedPhotos = []
    },

    createAdvert() {
      const payload = {
        active: this.vehicleActive,
        created: moment().format(this.timestampFormat),
        createdBy: this.user.email,
        featured: this.vehicleFeatured,
        vehicle: {
          reg: this.vehicleReg,
          make: this.vehicleMake,
          model: this.vehicleModel,
          variant: this.vehicleVariant,
          bodyType: this.vehicleBodyType,
          fuelType: this.vehicleFuelType,
          colour: this.vehicleColour,
          transmission: this.vehicleTransmission,
          engine: parseInt(this.vehicleEngineSize),
          power: parseInt(this.vehiclePower),
          seats: this.vehicleSeats,
          registrationDate: moment(this.vehicleRegistrationDate, 'DD/MM/YYYY').format(this.timestampFormat),
          taxBand: this.vehicleTaxBand,
          mileage: parseInt(this.vehicleMileage),
          motDue: moment(this.vehicleMotDue, 'DD/MM/YYYY').format(this.timestampFormat),
          owners: parseInt(this.vehicleOwners)
        },
        advert: {
          price: parseInt(this.vehiclePrice),
          title: this.advertTitle,
          subtitle: this.advertSubtitle,
          tag: this.advertTag,
          description: this.advertDescription,
          features: this.vehicleEquipmentSelected,
          serviceHistory: this.vehicleServiceHistory,
        },
        equipmentList: !this.vehicleEquipmentList.unavailable ? this.vehicleEquipmentList : null,
        images: this.uploadedPhotos
      }

      this.dbAddVehicle(payload)
        .then(() => {
          this.uploadedPhotos = [] // To avoid deleting photos from cloud storage
          this.showModal = false
        })
        .catch(err => {
          console.error(err)
        })
    },
    deletePhotoClick(event){
      console.log(event)
    }
  }
}
</script>

<style scoped>
.photo-preview {
  max-width: 300px;
  max-height: 300px;
  cursor: move;
}

#vehicle-reg {
  margin-right: 10px;
}

#searchButton {
  height: 60px;
}
.form-control{
  color: black;
  font-weight: bold;
  font-size: 1.1em;
}
.carReg {
  font-family: 'UKNumberPlate';
  font-size: 2em;
  background-color: #FFD900;
  color: #000;
}

.btnDelete {
  margin-top: 5px;
}

</style>
